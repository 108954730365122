import '@css/main.pcss';

// Vue
import Vue from "vue";

import "@/config/plugins"; // Misc JS plugins

// Animation library
import 'aos/dist/aos.css';
import AOS from 'aos';

// Needed for Age Gate
import VModal from 'vue-js-modal';
import VueCookie from 'vue-cookie';

// Vue plugins
import SvgIcon from '@/vue/SvgIcon.vue';
import MainMenu from '@/vue/MainMenu.vue';
import Accordion from '@/vue/accordion/Accordion.vue';
import AccordionGroup from '@/vue/accordion/AccordionGroup.vue';
import AccordionPanel from '@/vue/accordion/AccordionPanel.vue';
import VideoHero from '@/vue/VideoHero.vue';
import NewsSlider from '@/vue/NewsSlider.vue';
import Grid from "@/vue/Grid.vue";
import AgeGate from '@/vue/AgeGate.vue';
import PrivacyPopup from "@/vue/PrivacyPopup.vue";

// Global components
Vue.component('svg-icon', SvgIcon);
Vue.use(VModal);
Vue.use(VueCookie);

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],

  created() {
    AOS.init({
      easing: 'ease-in-out-quad',
      once: true,
    });
  },

  mounted() {
    window.lazySizes.init();
  },

  components: {
    'main-menu': MainMenu,
    'accordion': Accordion,
    'accordion-group': AccordionGroup,
    'accordion-panel': AccordionPanel,
    'video-hero': VideoHero,
    'news-slider': NewsSlider,
    'grid': Grid,
    'agegate': AgeGate,
    'privacy-popup': PrivacyPopup,
  }
});
