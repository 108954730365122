/**
 * Used to generate uuids inside components
 * Used in accordion
 */

let uuid = 0;

export default {
  beforeCreate() {
      this.uuid = uuid.toString();
      uuid += 1;
  },
};